/*
 * Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

export const dict = {
  ja: {
    "Confirm TOTP Code": "ワンタイムパスワード確認",
    "Sign In": "サインイン ",
    "Sign Up": "登録 ",
    "Sign Out": "サインアウト ",
    "Sign in to your account": "アカウントにサインイン ",
    Username: "ログインID ",
    Password: "パスワード ",
    "Enter your Username": " ",
    "Enter your Password": " ",
    "No account? ": "アカウントが無いとき ",
    "Forget your password? ": " ",
    "Reset password": "リセット ",
    "Reset Password": "リセット ",
    "User does not exist": "ユーザーが存在しません ",
    "User already exists": "既にユーザーが存在しています ",
    "Incorrect username or password": "ユーザー名かパスワードが異なります ",
    "Invalid password format": "パスワードの形式が無効です ",
    "Create account": "アカウントを作る ",
    "Forgot your password?": "リセット",
    "Change Password": "パスワードを変える ",
    "New Password": "新しいパスワード（大文字小文字英数8文字）",
    Email: "メールアドレス",
    "Phone Number": "電話番号",
    "Confirm a Code": "コードを確認",
    "Confirm Sign In": "サインインする",
    "Confirm Sign Up": "登録する",
    "Back to Sign In": "サインインに戻る",
    "Send code": "確認コードを送信",
    Sending: "送信中",
    "Attempt limit exceeded, please try after some time.":
      "制限に達しました。時間を置いて再度お試し下さい",
    Submitting: "送信中",
    Confirm: "確定",
    "Resend Code": "確認コードを再送信",
    Submit: "送信",
    Skip: "スキップ",
    Verify: "確認",
    "Verify Contact": "連絡先を確認",
    Code: "確認コード",
    "Code *": "確認コード",
    "Signing in": "認証中...",
    Confirming: "認証中...",
    "Incorrect username or password.": "ユーザ名かパスワードが異なります",
    "New password": "新しいパスワード（大文字小文字英数8文字）",
    "Confirm Password": "新しいパスワードを再入力（大文字小文字英数8文字）",
    "Confirmation Code": "確認コード",
    "Lost your code? ": "コードを失くしましたか？",
    "Account recovery requires verified contact information":
      "アカウントの復旧には確認済みの連絡先が必要です",
    "Invalid phone number format":
      "不正な電話番号の形式です。\n+12345678900 の形式で入力してください",
    "Create Account": "アカウントを作る",
    "Have an account? ": "アカウントを持っていますか？",
    "Sign in": "認証",
    "Enter your email": "メールアドレスを入力",
    "Setup TOTP": "QRコードをMFA対応アプリでスキャンします",
    "Create a new account": "新しいアカウントを作る",
    "Reset your password": "パスワードをリセットする",
    "An account with the given email already exists.":
      "入力されたメールアドレスのアカウントが既に存在します",
    "Username cannot be empty": "ユーザー名は入力必須です",
    "Password attempts exceeded": "サインインの試行回数が上限に達しました",
    "Sign In with Google": "Googleでサインイン",
    "Sign In with Facebook": "Facebookでサインイン",
    "Confirmation code cannot be empty": "検証コードを入力して下さい",
    "Password reset required for the user":
      "「パスワードのリセット」より、初回パスワードを変更して下さい",
    "Scan then enter verification code": "MFAアプリの登録を行って下さい",
  },
};
