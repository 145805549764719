import { Amplify, I18n } from "@aws-amplify/core";
import { API, Storage } from "aws-amplify";
import { dict } from "./AmplifyI18n";
import awsconfig from "./aws-exports";
import { Authenticator, TabItem, Tabs } from "@aws-amplify/ui-react";
import { StorageManager } from "@aws-amplify/ui-react-storage";
import "@aws-amplify/ui-react/styles.css";
import React, { useState } from "react";
// import dayjs from "dayjs";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

Amplify.configure(awsconfig);
I18n.putVocabularies(dict);
I18n.setLanguage("ja"); // 日本語の言語コード

export default function App() {
  const [selectedValue, setSelectedValue] = useState("construction-control");

  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);

  const handleDateChange = (date: Date | Date[] | null) => {
    if (date instanceof Date) {
      if (selectedStartDate && !selectedEndDate) {
        setSelectedEndDate(date);
      } else {
        setSelectedStartDate(date);
        setSelectedEndDate(null);
      }
    } else if (Array.isArray(date)) {
      setSelectedStartDate(date[0]);
      setSelectedEndDate(date[1]);
    }
    console.log(selectedStartDate);
    const test = selectedStartDate
      ?.toLocaleDateString("ja-JP", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      })
      .replace(/\//g, "-");
    console.log(test);
  };

  const handleChange = (event: any) => {
    setSelectedValue(event.target.value);
  };

  const downloadBlob = (blob: any, filename: any) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  };

  const handleDownload = async () => {
    if (selectedStartDate && selectedEndDate) {
      const startDate = selectedStartDate
        .toLocaleDateString("ja-JP", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .replace(/\//g, "-");

      const endDate = selectedEndDate
        .toLocaleDateString("ja-JP", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .replace(/\//g, "-");

      const apiName = "csv"; // replace this with your api name.
      const path = "/date"; //replace this with the path you have configured on your API
      const myInit = {
        body: {
          startDate: startDate,
          endDate: endDate,
        }, // replace this with attributes you need
        headers: {}, // OPTIONAL
      };

      try {
        const response = await API.post(apiName, path, myInit);
        console.log(response);
        const filename = `confirm/${startDate}_${endDate}_data.csv`;
        const result = await Storage.get(filename, { download: true });
        downloadBlob(result.Body, filename);
      } catch (error: any) {
        console.log(error.response);
        alert('通話時間計算中のためダウンロードが出来なかった可能性があります。少し時間を置いてから再度お試し下さい。');
      }

      // const payload = {
      //   startDate: startDate,
      //   endDate: endDate
      // };
      // console.log(startDate);
      // console.log(endDate);
      // try {
      //   await API.post('test0712', '/date', { body: payload });
      // } catch (error) {
      //   console.log('Error:', error);
      // }
    }
  };

  return (
    <Authenticator hideSignUp={true}>
      {({ signOut, user }) => (
        // <main>
        //   <h1>Hello {user?.username}</h1>
        //   <button onClick={signOut}>Sign out</button>
        // </main>
        // <div>
        //   <h1>架電履行確認システム</h1>
        <div className="container">
          <button className="logout-button" onClick={signOut}>
            ログアウト
          </button>
          {/* <h1>架電履行確認</h1> */}
          <Tabs justifyContent="flex-start">
            <TabItem title="アップロード">
              <h1>CSVの種類</h1>
              {/* <p>以下のフォームにIDとパスワードを入力してください。</p> */}
              <form>
                {/* <div className="form-group">
                        <label for="id">ID：</label>
                        <input type="text" id="id" name="id">
                    </div>
                    <div className="form-group">
                        <label for="password">パスワード：</label>
                        <input type="password" id="password" name="password">
                    </div>
                    <div className="login-button">
                        <button type="submit">ログイン</button>
                    </div> */}
                <div className="form-group">
                  {/* <label htmlFor="csv-type">CSVの種類：</label> */}
                  {/* <select id="csv-type" name="csv-type"> */}
                  <select value={selectedValue} onChange={handleChange}>
                    <option value="construction-control">工事統制</option>
                    <option value="special-notes-case-id">
                      特記事項(案件ID)
                    </option>
                    <option value="special-notes-base-id">
                      特記事項(拠点ID)
                    </option>
                    {/* <option value="telephone-performance-count">架電回数</option>    */}
                  </select>
                </div>
                {/* <div className="top-btn">
                      <button>工事統制</button>
                      <button>特記事項(案件ID)</button>
                      <button>特記事項(拠点ID)</button>
                    </div> */}
                <div className="upload-section">
                  <label htmlFor="file-upload">ファイルのアップロード：</label>
                  {/* <input type="file" id="file-upload" name="file-upload"/> */}
                  <StorageManager
                    // acceptedFileTypes={['image/*']}
                    accessLevel="public"
                    maxFileCount={100}
                    displayText={{
                      // some text are plain strings
                      dropFilesText: "ファイルをドロップ",
                      browseFilesText: "ファイルを選択",
                    }}
                    isResumable
                    path={selectedValue + "/"}
                    acceptedFileTypes={[]}
                  />
                  {/* <label className="custom-file-upload" htmlFor="file-upload">ファイルを選択</label> */}
                </div>
                {/* <div>
                        <button type="submit">送信</button>
                    </div> */}
              </form>
            </TabItem>
            <TabItem title="ダウンロード">
              <h1>履行確認記録</h1>
              <div className="download-section">
                {/* <button onClick={async()=>{
                    console.log("01")
                    // To check for existence of a file
                    // await Storage.get('test.txt', { validateObjectExistence: true });
                    // Storage.get('test.txt')
                    // .then(result => console.log(result))
                    // .catch(err => console.log(err));
                    const now = dayjs();
                    console.log(now)
                    const result = await Storage.get("confirm/" + now.format('YYYY-MM-DD') + "_data.csv", { download: true });
                    downloadBlob(result.Body, 'data.csv');
                  }}>履行確認CSVをダウンロード(1日)</button> */}

                {/* <button onClick={handleDownload}>履行確認CSVダウンロード</button> */}

                <div className="calendar-container">
                  <Calendar
                    value={
                      selectedStartDate && selectedEndDate
                        ? [selectedStartDate, selectedEndDate]
                        : null
                    }
                    selectRange={true}
                    onChange={handleDateChange as any}
                  />
                </div>
                <div>
                  {selectedStartDate
                    ? selectedStartDate.toLocaleDateString()
                    : "-"}
                  ～
                  {selectedEndDate ? selectedEndDate.toLocaleDateString() : "-"}
                </div>
                <button className="download-button" onClick={handleDownload}>
                  履行確認CSVダウンロード
                </button>
                {/* <a href="#">履行確認CSVをダウンロード(1か月)</a> */}
              </div>
            </TabItem>
          </Tabs>
        </div>
        // </div>
      )}
    </Authenticator>
  );
}
