/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cloud_logic_custom": [
        {
            "name": "csv",
            "endpoint": "https://y02mouajfl.execute-api.ap-northeast-1.amazonaws.com/stg",
            "region": "ap-northeast-1"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-northeast-1:28e0a662-a16d-4fc5-b454-dc22137de32d",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_kCl7XQ6wl",
    "aws_user_pools_web_client_id": "640gqdqdnttn8rcl4eo45t02fr",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "fulfill-conform-stg",
    "aws_content_delivery_bucket_region": "ap-northeast-1",
    "aws_content_delivery_url": "https://d3miwh5d7ts89y.cloudfront.net",
    "aws_user_files_s3_bucket": "storage90703-stg",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;
